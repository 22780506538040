const timeouts = [];
const addTimeout = (callback, delay, ...args) => {
  const timeId = setTimeout(callback, delay, ...args);
  timeouts.push(timeId);
  return timeId;
};
const cancelAlltimeouts = () => {
  timeouts.forEach((timeId) => {
    clearTimeout(timeId);
  });
  timeouts.length = 0;
};
export {addTimeout, cancelAlltimeouts};
